@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --black: rgba(8, 17, 19, 1);
  --black-opacity: rgba(8, 17, 19, 0.3);
  --blue-100: rgba(208, 222, 224, 1);
  --blue-200: rgba(223, 236, 240, 1);
  --blue-300: rgba(201, 228, 235, 1);
  --blue-400: rgba(111, 184, 201, 1);
  --blue-500: rgba(37, 86, 98, 1);
  --blue-500-opacity: rgba(37, 86, 98, 0.85);
  --blue-600: rgba(0, 54, 68, 1);
  --green: rgba(59, 142, 107, 1);
  --green-200: rgba(216, 232, 225);
  --grey-100: rgba(248, 248, 248, 1);
  --grey-200: rgba(244, 244, 244, 1);
  --grey-300: rgba(235, 235, 235, 1);
  --grey-400: rgba(96, 110, 114, 1);
  --grey-500: rgba(72, 72, 72, 1);
  --h1-regular-28-font-family: 'Inter-Regular', Helvetica;
  --h1-regular-28-font-size: 28px;
  --h1-regular-28-font-style: normal;
  --h1-regular-28-font-weight: 400;
  --h1-regular-28-letter-spacing: 0px;
  --h1-regular-28-line-height: 42px;
  --h2-regular-24-font-family: 'Inter-Regular', Helvetica;
  --h2-regular-24-font-size: 24px;
  --h2-regular-24-font-style: normal;
  --h2-regular-24-font-weight: 400;
  --h2-regular-24-letter-spacing: 0px;
  --h2-regular-24-line-height: 36px;
  --h3-regular-18-font-family: 'Inter-Regular', Helvetica;
  --h3-regular-18-font-size: 18px;
  --h3-regular-18-font-style: normal;
  --h3-regular-18-font-weight: 400;
  --h3-regular-18-letter-spacing: 0px;
  --h3-regular-18-line-height: 27px;
  --h4-regular-16-font-family: 'Inter-Regular', Helvetica;
  --h4-regular-16-font-size: 16px;
  --h4-regular-16-font-style: normal;
  --h4-regular-16-font-weight: 400;
  --h4-regular-16-letter-spacing: 0px;
  --h4-regular-16-line-height: 24px;
  --orange: rgba(229, 140, 75, 1);
  --orange-200: rgba(250, 224, 205);
  --pink: rgba(224, 88, 145, 1);
  --red: rgba(217, 99, 99, 1);
  --red-100: rgba(255, 211, 211, 1);
  --small-text-regular-12-font-family: 'Inter-Regular', Helvetica;
  --small-text-regular-12-font-size: 12px;
  --small-text-regular-14-font-size: 14px;
  --small-text-regular-12-font-style: normal;
  --small-text-regular-12-font-weight: 400;
  --small-text-regular-12-letter-spacing: 0px;
  --small-text-regular-12-line-height: 18px;
  --white: rgba(255, 255, 255, 1);
  --yellow: rgba(229, 186, 75, 1);
  --yellow-200: rgba(247, 234, 201);
}

body {
  font-family: var(--small-text-regular-12-font-family);
  font-size: var(--small-text-regular-12-font-size);
  font-style: var(--small-text-regular-12-font-style);
  font-weight: var(--small-text-regular-12-font-weight);
  letter-spacing: var(--small-text-regular-12-letter-spacing);
  line-height: var(--small-text-regular-12-line-height);
  color: var(--black);
}

button {
  font-family: var(--small-text-regular-12-font-family);
  font-size: var(--small-text-regular-14-font-size);
  font-style: var(--small-text-regular-12-font-style);
  font-weight: var(--small-text-regular-12-font-weight);
  letter-spacing: var(--small-text-regular-12-letter-spacing);
  line-height: var(--small-text-regular-12-line-height);
}

label {
  font-family: var(--small-text-regular-12-font-family);
  font-size: var(--small-text-regular-14-font-size);
  font-style: var(--small-text-regular-12-font-style);
  font-weight: var(--small-text-regular-12-font-weight);
  letter-spacing: var(--small-text-regular-12-letter-spacing);
  line-height: var(--small-text-regular-12-line-height);
}

p {
  font-family: var(--small-text-regular-12-font-family);
  font-size: var(--small-text-regular-14-font-size);
  font-style: var(--small-text-regular-12-font-style);
  font-weight: var(--small-text-regular-12-font-weight);
  letter-spacing: var(--small-text-regular-12-letter-spacing);
  line-height: var(--small-text-regular-12-line-height);
}

h5 {
  font-family: var(--h4-regular-16-font-family);
  font-size: var(--small-text-regular-14-font-size);
  font-style: var(--h4-regular-16-font-style);
  font-weight: var(--h4-regular-16-font-weight);
  letter-spacing: var(--h4-regular-16-letter-spacing);
  line-height: var(--h4-regular-16-line-height);
}

h4 {
  font-family: var(--h4-regular-16-font-family);
  font-size: var(--h4-regular-16-font-size);
  font-style: var(--h4-regular-16-font-style);
  font-weight: var(--h4-regular-16-font-weight);
  letter-spacing: var(--h4-regular-16-letter-spacing);
  line-height: var(--h4-regular-16-line-height);
}

h3 {
  font-family: var(--h3-regular-18-font-family);
  font-size: var(--h3-regular-18-font-size);
  font-style: var(--h3-regular-18-font-style);
  font-weight: var(--h3-regular-18-font-weight);
  letter-spacing: var(--h3-regular-18-letter-spacing);
  line-height: var(--h3-regular-18-line-height);
}

h2 {
  font-family: var(--h2-regular-24-font-family);
  font-size: var(--h2-regular-24-font-size);
  font-style: var(--h2-regular-24-font-style);
  font-weight: var(--h2-regular-24-font-weight);
  letter-spacing: var(--h2-regular-24-letter-spacing);
  line-height: var(--h2-regular-24-line-height);
}

h1 {
  font-family: var(--h1-regular-28-font-family);
  font-size: var(--h1-regular-28-font-size);
  font-style: var(--h1-regular-28-font-style);
  font-weight: var(--h1-regular-28-font-weight);
  letter-spacing: var(--h1-regular-28-letter-spacing);
  line-height: var(--h1-regular-28-line-height);
}
.ant-picker-ok .ant-btn:disabled {
  background-color: #f4f4f4;
}
.ant-picker-ok .ant-btn {
  background-color: #6fb8c9;
}

.dragger-hover {
  background-color: white;
  border: solid 1px black;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.dragger-hover:hover {
  background-color: lightblue;
  border-color: blue;
}

.delete-icon:hover path {
  fill: red; /* Change the color to red */
}

.MuiBadge-colorSuccess {
  background-color: '#3B8E6B';
}

.ant-table-thead .ant-table-cell {
  background-color: var(--grey-300) !important;
}